var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { isRight } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/pipeable';
import fromPairs from 'lodash/fromPairs';
import stubTrue from 'lodash/stubTrue';
import { mergeProps } from 'react-aria';
import { DecodeError, notUndefined } from '@mablemarket/common-lib';
export var setRef = function (ref, value) {
    // eslint-disable-next-line no-unused-expressions
    ref && (typeof ref === 'function'
        ? ref(value)
        // This cast is fine, React just doesn't want us to prevent us from
        // casually overriding refs
        : ref.current = value);
};
export var composeRefs = function () {
    var refs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        refs[_i] = arguments[_i];
    }
    return function (value) {
        refs.filter(notUndefined).forEach(function (ref) { return setRef(ref, value); });
    };
};
/**
 * Adds a custom test to a yup schema via an io-ts codec, and ensures the schema
 * type is a valid input type for the codec. There is some possibility we could
 * do extensive monkey patching with yup.addMethod to make this less annoying.
 * @param schema yup schema
 * @param codec A codec whose input type, I (usually unknown), matches the schema type
 */
export var refineYupSchema = function (schema, codec) {
    return schema.test({
        name: codec.name,
        test: function (value) {
            var either = codec.decode(value);
            return isRight(either) ? true : this.createError({ message: new DecodeError(either.left).message });
        },
    });
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export var createUploadWidget = function (client, options, callback) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return window.cloudinary.createUploadWidget(__assign({ cloudName: 'mable', api_key: '923524768139652', uploadPreset: 'SignedServerSideCrop', sources: ['local', 'url'], theme: 'white', multiple: false, resource_type: 'image', clientAllowedFormats: ['png', 'gif', 'jpg', 'jpeg'], cropping: true, croppingShowDimensions: true, croppingValidateDimensions: true, croppingShowBackButton: false, croppingDefaultSelectionRatio: 0.1, showSkipCropButton: false, showPoweredBy: false, styles: {
            palette: {
                window: '#FFFFFF',
                windowBorder: '#515151',
                tabIcon: '#0A24CC',
                menuIcons: '#515151',
                textDark: '#515151',
                textLight: '#FFFFFF',
                link: '#0A24CC',
                action: '#0A24CC',
                inactiveTabIcon: '#515151',
                error: '#D13030',
                inProgress: '#0A24CC',
                complete: '#005B19',
                sourceBg: '#FFFFFF',
            },
            fonts: {
                default: null,
                'sans-serif': {
                    url: null,
                    active: true,
                },
            },
        }, uploadSignature: function (callback, paramsToSign) { return __awaiter(void 0, void 0, void 0, function () {
            var res;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, client.req('POST /v1/cloudinaryUploadSignature', { params: paramsToSign })];
                    case 1:
                        res = _b.sent();
                        if ((_a = res.body) === null || _a === void 0 ? void 0 : _a.signature) {
                            callback(res.body.signature);
                        }
                        return [2 /*return*/];
                }
            });
        }); } }, options), callback);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export var imageFromCloudinaryResult = function (result) {
    var newImage = {
        type: 'cloudinary',
        externalBucketId: 'mable',
        externalId: result.info.public_id,
        version: result.info.version.toString(),
        format: result.info.format,
        width: result.info.width,
        height: result.info.height,
    };
    return newImage;
};
export var uploadImageURL = function (client, url) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve, reject) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                var uploader = createUploadWidget(client, {}, function (error, result) {
                    if (!error) {
                        if (result && result.event === 'success') {
                            var newImage = imageFromCloudinaryResult(result);
                            resolve(newImage);
                        }
                    }
                    else {
                        reject(error);
                    }
                });
                uploader.open(null, {
                    files: [url],
                });
            })];
    });
}); };
/** A dumb hack to allow coexistance of react-aria hooks and certain libraries that require event propagation to work
 *  see https://github.com/adobe/react-spectrum/issues/2100 for possible resolution in the future
 */
export var reactAriaAllowPropagation = function (props) {
    return mergeProps(pipe(Object.keys(props), function (keys) { return keys.filter(function (key) { return !!key.match(/^on[A-Z]/); }); }, function (eventKeys) { return eventKeys.map(function (key) { return [key, function (e) {
            Object.assign(e, { stopPropagation: stubTrue, preventDefault: stubTrue });
        }]; }); }, fromPairs), props);
};
