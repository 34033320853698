import entries from 'lodash/entries';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';

import { Category, Product, ProductVariant, RetailUnit, SampleRequest, SellerDisplayInfo, Transaction } from '@mablemarket/core-api-client';

import { AppliedFilters, AppliedFiltersSerialize } from './helpers/FiltersHelpers';

// TODO: Replace with something iike query-string, make sure deps are shared to not inflate bundle
const simpleEncodeQueryParams = (params: Record<string, string | undefined | null>) => (
  entries(params)
    .map(([k, v]) => (v ? `${k}=${encodeURIComponent(v)}` : undefined))
    .filter(x => !!x)
    .join('&')
);

// Generates URI paths for mable-web, and hopefully for mobile if we keep it consistent.

export const supportTopics = {
  'tos-brand': '/articles/360033868171-Brand-Terms-of-Use',
  'tos-retailer': '/articles/360033868211-Retailer-Terms-of-Use',
  privacy: '/articles/360033862811-Privacy-Policy',
  contact: '/articles/360039955771-Contact-Us',
  'seller-faq': '/categories/360002135051-Mable-for-Brands',
  'seller-payment': '/articles/360033670592-When-do-I-get-paid',
  'seller-orders': '/articles/360045675272-How-to-fulfill-an-order',
  'seller-shopify': '/articles/4862075593620-Integrating-with-Shopify',
  'seller-product-management': '/articles/4417195396500-Adding-or-removing-a-product',
  'how-to-pay': '/articles/6320532054292-How-do-I-pay-for-orders-',
  samples: '/articles/5992330574484-How-do-I-request-samples-',
  'samples-seller': '/articles/360058202352-How-does-the-Sample-Program-work-',
  productsAffectedByHeatWarning: '/articles/4402259712404',
  firstOrderGuarantee: '/articles/360061851271-Mable-First-Order-Guarantee',
  sellerReferralWidget: '/articles/4405665247892',
  manageExistingAccounts: '/articles/4405523003796',
  photoGuidelines: '/articles/4408211934356-Mable-Product-Photography-Guidelines',
  request: '/requests/new',
  shippingPolicy: '/articles/4414980954644',
  'sample-prospecting': '/articles/4418943572372',
  'messaging-retailers': '/articles/7011195273236',
  'messaging-brands': '/articles/7009590038292',
  'resale-certificate': '/articles/4412320787348-Do-I-need-a-resale-certificate',
  'priority-buyer': '/articles/10429223214484',
  'mclane-buyer': '/articles/18843170003476-McLane-Emerging-Brands-Program-Overview',
} as const;

export const support = (options?: { topic?: keyof typeof supportTopics }) => {
  const base = 'https://support.meetmable.com/hc/en-us';
  const { topic } = options ?? {};
  return base + (topic ? supportTopics[topic] : '');
};

interface ProductArgs {
  product: Pick<Product, 'links'>;
  variant?: Pick<ProductVariant, 'id'>;
}

export const product = ({ product, variant }: ProductArgs) => {
  // TODO: Handle link have query params
  const to = product.links.detailPage
    + (variant ? `?variant=${variant.id}` : '');
  return window.encodeURI(to);
};

export const seller = ({ seller }: { seller: Pick<SellerDisplayInfo, 'id'> | Pick<SellerDisplayInfo, 'slug'> }) => {
  return `/${('slug' in seller && seller.slug) || ('id' in seller && seller.id)}`;
};

export const orders = (opts?: {
  productSellerIds?: number[];
}) => {
  const queryParams = queryString.stringify({ productSellerIds: opts?.productSellerIds }, { arrayFormat: 'comma' });
  const qs = queryParams.length > 0 ? `?${queryParams}` : '';
  return `/orders${qs}`;
};

export const order = ({ transaction }: { transaction: Pick<Transaction, 'id'> }) => {
  return `/orders/${transaction.id}`;
};

export const AccountSections = ['account', 'members', 'locations', 'billing'] as const;
export type AccountSection = typeof AccountSections[number];
export const buyerAccount = (section?: AccountSection) => {
  if (!section || section === 'account') {
    return '/account';
  }
  return `/account/${section}`;
};

export const buyerSamples = () => {
  return '/samples';
};

export const buyerVerification = (opts?: { referrer?: string }) => {
  return `/verification${opts?.referrer ? `?referrer=${opts.referrer}` : ''}`;
};

export const buyerOrderGuide = () => {
  return '/order-guide';
};

export const sellerDashboard = (opts?: { showModal: boolean }) => {
  return `/vendor${(opts?.showModal) ? '#sm' : ''}`;
};

export const sellerPartnerOnboardingForm = (opts: { partnerSlug: string }) => {
  return `/vendor/onboarding/${opts.partnerSlug}/form`;
};

export const sellerTransactions = (opts?: {
  withAccountId?: number;
}) => {
  const qp = {
    ...(opts ?? {}),
  };
  return [
    '/vendor/orders',
    Object.keys(qp).length > 0 && queryString.stringify(qp),
  ].filter(s => s).join('?');
};

export const sellerTransaction = ({ transaction }: { transaction: Pick<Transaction, 'id'> }) => {
  return `/vendor/orders/${transaction.id}`;
};

export const sellerOrderModification = ({ order }: { order: Pick<Transaction, 'id'> }) => {
  return `/vendor/orders/${order.id}/modify`;
};

export const sellerAccount = (opts?: { section?: 'company' | 'people' | 'po' | 'shop' }) => {
  const { section } = opts ?? {};
  return `/vendor/account${section ? `?section=${section}` : ''}`;
};

export const sellerProducts = () => {
  return '/vendor/products';
};

export const sellerEditRetailUnit = ({ retailUnit }: { retailUnit: Pick<RetailUnit, 'id'> }) => {
  return `/vendor/products/${retailUnit.id}`;
};

export const sellerProductWizard = ({ step, productId }: { step: 'what' | 'enter' | 'edit' | 'select' | 'confirm', productId?: number }) => {
  const queryParams = queryString.stringify({ id: productId, step });
  const qs = queryParams.length > 0 ? `?${queryParams}` : '';
  return `/vendor/products/wizard${qs}`;
};

export const SellerStoreSectionValues = ['media', 'about', 'social', 'settings'] as const;
export type SellerStoreSection = typeof SellerStoreSectionValues[number];
export const sellerStore = (opts?: { section?: SellerStoreSection }) => {
  return `/vendor/store${opts?.section ? `#${opts.section}` : ''}`;
};

export const sellerCustomers = () => {
  return '/vendor/customers';
};

export const sellerCustomerDetail = ({ accountId }: { accountId: number }) => {
  return `/vendor/customers/${accountId}`;
};

export const sellerSampleRequests = (opts?: { page?: number }) => {
  const qp = opts?.page ? `?${queryString.stringify({ p: opts.page })}` : '';
  return `/vendor/samples${qp}`;
};

export const sellerSampleRequest = ({ sampleRequest }: { sampleRequest: Pick<SampleRequest, 'id'> }) => {
  return `/vendor/samples/${sampleRequest.id}`;
};

export const sellerIntegrations = () => {
  return '/vendor/integrations';
};

export const sellerShopify = () => {
  return '/vendor/integrations/shopify';
};

export const sellerShopifySetup = () => '/vendor/integrations/shopify/start';
export const sellerShopifyFinish = () => '/vendor/integrations/shopify/finish';

export const sellerShopifyProductMatching = () => '/vendor/integrations/shopify/product-matching';

export const engineeringBlog = () => {
  return 'https://mable.engineering';
};

/**
 * If given an invitation token, return a signup link that redirects to the target. Otherwise,
 * just return the redirect target path.
 */
export const vendorSignUp = (opts: {
  redirectPath?: string | null;
  invitationToken?: string | null;
  email?: string;
}) => {
  const { redirectPath, invitationToken, email } = opts;
  const target = redirectPath ?? 'vendor';
  const queryParams = simpleEncodeQueryParams({
    invite: invitationToken ?? '',
    redirect: redirectPath,
    email,
  });
  return opts.invitationToken
    ? `vendor-sign-up?${queryParams}`
    : target;
};

export const buyerInvite = (opts: {
  invitationToken: string;
  email: string;
}) => {
  const { invitationToken, email } = opts;
  const queryParams = simpleEncodeQueryParams({
    invite: invitationToken,
    email,
  });
  return `sign-up/invite?${queryParams}`;
};

export const buyerInvoices = () => {
  return '/invoices';
};

export const buyerInvoice = (opts: {
  id: number
}) => {
  return `/invoices/${opts.id}`;
};

export const home = () => {
  return '/';
};

export const buyerDashboard = () => {
  return '/dashboard';
};

export const sellerRequestChanges = (opts?: {
  email?: string;
  changes?: 'add' | 'update' | 'both';
}) => {
  const { email, changes } = opts ?? {};
  const queryParams = simpleEncodeQueryParams({
    email,
    i_would_like_to: changes && {
      add: 'Add new products',
      update: 'Update my page',
      both: 'Both',
    }[changes],
  });
  return `https://share.hsforms.com/1g0ncmVvFS0GcwydXuQGzOQ37zej${queryParams ? `?${queryParams}` : ''}`;
};

export const checkout = (opts?: {
  sellerIds?: number[];
}) => {
  const { sellerIds } = opts ?? {};
  const queryParams = queryString.stringify({
    sellerIds,
  });
  return `/checkout${queryParams ? `?${queryParams}` : ''}`;
};

export const checkoutComplete = () => {
  return '/checkout/checkout_complete';
};

type SearchOptions = {
  sellerSlug?: string;
  filters?: AppliedFilters;
  tab?: 'sellers' | 'variants';
  query?: string;
  // TODO: With the advent of open enums can we type this and SearchSortMethod?
  sortMethod?: string;
};

export const search = (options?: SearchOptions) => {
  const { sellerSlug, filters, tab, query, sortMethod } = options ?? {};
  const queryParams = simpleEncodeQueryParams({
    filter: filters && AppliedFiltersSerialize.encode(filters),
    t: tab,
    q: query,
    sortMethod,
  });
  return `/${sellerSlug || 'search'}${queryParams ? `?${queryParams}` : ''}`;
};

export const collection = ({ category }: { category: Pick<Category, 'slug'> }) => {
  return `/collection/${category.slug}`;
};

export const apl = ({ category }: { category: Pick<Category, 'slug'> }) => {
  return `/apl/${category.slug}`;
};

export const ppl = ({ category }: { category: Pick<Category, 'slug'> }) => {
  return `/ppl/${category.slug}`;
};

type CategoryOptions = {
  category: Pick<Category, 'id' | 'slug'> & Partial<Pick<Category, 'isCuratedCollection'>>;
  filters?: AppliedFilters;
};

export const category = ({ category, filters }: CategoryOptions) => {
  if (category.isCuratedCollection) {
    return collection({ category });
  }
  const queryParams = simpleEncodeQueryParams({
    filter: (filters && !isEmpty(filters)) ? AppliedFiltersSerialize.encode(filters) : undefined,
  });
  return `/category/${category.id}/${category.slug}/${queryParams ? `?${queryParams}` : ''}`;
};

export const reorder = ({ seller }: { seller?: Pick<SellerDisplayInfo, 'slug'> } = {}) => {
  return `/reorder/${seller?.slug ?? ''}`;
};

export const brandReferralLink = ({ buyerReferralCode, buyerName }: {
  buyerReferralCode: string,
  buyerName?: string
}) => {
  const queryParams = simpleEncodeQueryParams({
    // This is the name of the HubSpot contact property where we want the code to end up.
    // The query string is parsed by the HubSpot form automatically and populates a hidden
    // field of the same property name.
    mable_referral_code: buyerReferralCode,
  });

  return `/start-selling?${queryParams}`;
};

export const inviteBrands = () => {
  return '/invite-brands';
};

/**
 * Point directly to the seller's referral page, with query params.
 * Sending people to this link is fine, but don't display it. That's what buyerReferralShortLink is for.
 */
export const buyerReferralLink = ({
  slug, referralCode, utmSource, utmMedium, utmCampaign }: {
    slug: string;
    referralCode: string;
    utmSource?: string;
    utmMedium?: string;
    utmCampaign?: string;
  }) => {
  const qs = queryString.stringify({
    rc: `${referralCode}100`,
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign,
  });
  return `/${encodeURIComponent(slug)}?${qs}`;
};

/** A shortened, displayable buyer referral link. */
export const buyerReferralShortLink = ({ sellerSlug }: { sellerSlug: string }) => {
  return `${sellerSlug.replace(/-/g, '')}.meetmable.com`;
};

export const savedProducts = () => {
  return '/saved';
};

export const messages = () => {
  return '/messages';
};

export const messageChannel = (messageChannelUrl: string) => {
  return `/messages?channel=${encodeURIComponent(messageChannelUrl)}&view=channel`;
};

export const newBuyerMessageChannel = (buyerId: number) => {
  return `/messages/buyer/${buyerId}`;
};
