import * as t from 'io-ts';

import { MableError } from './MableError';

// Adapted from https://github.com/OliverJAsh/io-ts-reporters
const formatValidationError = (error: t.ValidationError) => {
  const jsToString = (value: t.mixed) => (value === undefined ? 'undefined' : JSON.stringify(value));
  const { path } = error.context
    .reduce((acc, c) => {
      let pathComponent = '';
      // When one of the things in the context is an IntersectionType, the next thing
      // is just an index that says which part of the intersection this error is about.
      // It's not an actual path into the value that errored, so ignore it.
      if (c.key !== '' && !(acc.lastType instanceof t.IntersectionType || acc.lastType instanceof t.ExactType)) {
        pathComponent = Number.isNaN(Number(c.key)) ? `.${c.key}` : `[${c.key}]`;
      }
      return { path: acc.path + pathComponent, lastType: c.type };
    }, { path: '', lastType: null as unknown });

  const lastContext = error.context[error.context.length - 1];
  if (!lastContext) {
    // TODO?
  }
  const expectedType = lastContext.type.name;

  const firstContext = error.context[0];
  if (!firstContext) {
    // TODO?
  }
  const topLevelType = firstContext.type.name;

  return `\nExpecting ${expectedType}\n${
    path === '' ? '' : `at ${path} of ${topLevelType}\n`
  }but instead got: ${jsToString(error.value)}.`;
};

export class DecodeError extends MableError<'DecodeError'> {
  public errors: t.Errors;

  public constructor(errors: t.Errors, decoding?: unknown, opts?: { status?: number }) {
    const formattedErrors = errors.map(formatValidationError).join('\n');
    const maybeDecoding = decoding ? `\n\nWhile attempting to decode value: ${JSON.stringify(decoding)}` : '';
    const message = `${formattedErrors}${maybeDecoding}`;
    super({ code: 'DecodeError', message, status: opts?.status ?? 500, data: undefined });
    // Terrible workaround for confusing TypeScript nonsense.
    // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, DecodeError.prototype);
    if (Error.captureStackTrace) { // Not available in browsers
      // Omit this constructor from the stack trace of the error.
      Error.captureStackTrace(this, DecodeError);
    }
    this.errors = errors;
  }
}
