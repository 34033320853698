/* eslint-disable max-classes-per-file, no-underscore-dangle */

import BigNumber from 'bignumber.js';
import { isLeft } from 'fp-ts/lib/These';
import * as t from 'io-ts';

import { DecodeError, EnumClosed, EnumOpen, EnumOpenValues, isMableError, MableError, MableServerResponseError, numberOrUndefined, OneOf, ParcelDimensions, SlugBrand, unexpected } from '@mablemarket/common-lib';

// Like the one in api-server-support, but we don't have to care about Postgres infinity.
const decodeDateFromISOString = (u: unknown, c: t.Context): t.Validation<Date> => {
  if (u instanceof Date) {
    return t.success(u);
  }
  const s = t.string.validate(u, c);
  if ('left' in s) return s;
  const d = new Date(s.right);
  return Number.isNaN(d.getTime()) ? t.failure(u, c) : t.success(d);
};

export interface DateFromISOStringC extends t.Type<Date, string, unknown> {
  readonly _tag: 'DateFromISOString';
}

export class DateFromISOStringType extends t.Type<Date, string, unknown> implements DateFromISOStringC {
  readonly _tag = 'DateFromISOString';

  constructor() {
    super(
      'DateFromISOString',
      (u): u is Date => u instanceof Date,
      decodeDateFromISOString,
      a => (typeof a === 'string' ? a : a.toISOString()),
    );
  }
}
export const DateFromISOString = new DateFromISOStringType();

export interface DateWithoutTimeFromISOStringC extends t.Type<Date, string, unknown> { }
export const DateWithoutTimeFromISOString: DateWithoutTimeFromISOStringC = new t.Type(
  'DateWithoutTimeFromISOString',
  (u): u is Date => u instanceof Date,
  (u, c) => {
    const d = decodeDateFromISOString(u, c);
    if ('left' in d) return d;
    const dateString = t.string.validate(u, c);
    if ('left' in dateString) return dateString;
    const parts = (dateString.right).split('-');
    d.right.setFullYear(parseInt(parts[0], 10));
    d.right.setMonth(parseInt(parts[1], 10) - 1);
    d.right.setDate(parseInt(parts[2], 10));
    d.right.setHours(0, 0, 0, 0);
    return d;
  },
  (a) => {
    if (typeof a === 'string') return a;
    const month = `${a.getMonth() + 1}`.padStart(2, '0');
    const day = `${a.getDate()}`.padStart(2, '0');
    return `${a.getFullYear()}-${month}-${day}`;
  },
);

// Like the one in api-server-support, but slightly different in that this NumberFromString doesn't fail on NaN.
// Not sure whether this is desirable for clients or not.
// This version will also pass through strings if you try to encode them - for example if a type error causes you to have
// a string that the types think is a BigNumber and you try to pass it as a parameter when making an API request, we'll
// just pass your string through to the API as-is.
export interface NumberFromStringC extends t.Type<BigNumber, string, unknown> {
  readonly _tag: 'NumberFromString';
}
export class NumberFromStringType extends t.Type<BigNumber, string, unknown> implements NumberFromStringC {
  readonly _tag = 'NumberFromString';

  constructor() {
    super(
      'NumberFromString',
      (u): u is BigNumber => BigNumber.isBigNumber(u),
      (u, c) => {
        if (BigNumber.isBigNumber(u)) {
          return t.success(u);
        }
        if (typeof u === 'number' || typeof u === 'string') {
          return t.success(new BigNumber(u));
        }
        return t.failure(u, c);
      },
      (a) => {
        if (typeof a === 'string') return a;
        if (typeof a === 'number') return (new BigNumber(a)).toFixed();
        return a.toFixed();
      },
    );
  }
}
export const NumberFromString = new NumberFromStringType();

export const ParcelDimensionsFromString = new t.Type<ParcelDimensions, string, unknown>(
  'ParcelDimensionsFromString',
  (u): u is ParcelDimensions => ParcelDimensions.is(u),
  (u, c) => {
    if (ParcelDimensions.is(u)) return t.success(u);
    if (typeof u === 'string') {
      const parts = u.replace(/\(|\)/g, '').split(',');
      if (parts.length !== 3) return t.failure(u, c);
      const [length, height, width] = parts.map(p => numberOrUndefined(p));
      return t.success({ length, height, width } satisfies ParcelDimensions);
    }
    return t.failure(u, c);
  },
  a => `(${a.length},${a.height},${a.width})`,
);
export type ParcelDimensionsFromString = t.TypeOf<typeof ParcelDimensionsFromString>;


// Clients need to be permissive when decoding certain enums and oneOf unions,
// as they may become out of date with what the server is sending. This requires
// a different set of codecs and types from the server. The following product
// unions of the expected types plus and object that carries the unexpected
// value

// The brand will keep the unknown type from consuming all other values in the
// encoded union
export interface OneOfUnexpectedValueBrand {
  readonly OneOfUnexpectedValue: unique symbol;
}
export type OneOfUnexpectedValue = t.Branded<unknown, OneOfUnexpectedValueBrand>;
const OneOfUnexpectedValue = t.brand(
  t.unknown,
  // Exclude undefined, because otherwise decoding inside of a partial will
  // result in an { __unexpected: undefined } rather than an omission
  (n): n is OneOfUnexpectedValue => n !== undefined,
  'OneOfUnexpectedValue',
);
const OneOfUnexpected = unexpected(OneOfUnexpectedValue);

/** A union of types from an api-spec `oneOf` that supports decoding unexpected values
  * to prevent old clients from generating decode errors due to updated responses
 */
export const OneOfOpen = <A extends t.Mixed, B extends t.Mixed, C extends t.Mixed[]>(codecs: [A, B, ...C]) => (
  t.union([...codecs, OneOfUnexpected])
);
export type OneOfOpenValues<T> = T | t.TypeOf<typeof OneOfUnexpected>;
export type OneOfOpenEncodedValues<T> = T | t.OutputOf<typeof OneOfUnexpected>;

/** Equivalent to `OneOf`, renamed to support code generation */
export const OneOfClosed = OneOf;


/** Equivalent to `EnumClosed`, renamed to support code generation */
export const CodegenEnumClosed = EnumClosed;
/** Equivalent to `EnumOpen`, renamed to support code generation */
export const CodegenEnumOpen = EnumOpen;
/** Equivalent to `EnumOpenValues`, renamed to support code generation */
export type CodegenEnumOpenValues<S extends string> = EnumOpenValues<S>;


export type HTTPMethod = 'get' | 'post' | 'put' | 'patch' | 'delete' | 'head' | 'options' | 'trace';
export type InputLocation = 'body' | 'path' | 'query' | 'header';

export interface LoggingDelegate {
  log: (log: string) => void;
}

type FetchType = typeof fetch;
export interface Config {
  fetch: FetchType;
  baseUrl: string;
  // Send in result of uuid() generation. This needs to be specified due to a polyfill needed just for react-native.
  uniqueId: string;
  loggingDelegate?: LoggingDelegate;
  /** If true, shorten the `uniqueId` for readability. Reduces uniqueness, so intended for local dev only. */
  prettyCorrelationIds?: boolean;
  timeout?: number;
  additionalHeaders?: Record<string, string>;
}

export interface ApiResponse<ResponseType> {
  readonly body: ResponseType;
  readonly rawResponse: Response;
}

/**
 * It's important that we only call getJsonBody once per response, since the `res.json()` will
 * 'drain the stream' of response data. Calling `res.json()` twice on the same res will throw.
 */
async function getJsonBody(res: Response, opts: {
  loggingDelegate?: LoggingDelegate;
  currentRequestNumber: number;
  href: string;
}): Promise<unknown | undefined> {
  const contentType = (res.headers.get('content-type') || '').toLowerCase() || undefined;
  let retVal: unknown;
  if (contentType && contentType.includes('application/json')) {
    retVal = await res.json();
  }
  // eslint-disable-next-line no-unused-expressions
  opts.loggingDelegate?.log(`[api-client] Got response #${opts.currentRequestNumber} ${opts.href} ${JSON.stringify(retVal, null, 2)}`);
  return retVal;
}

const getKeys = <T extends {}>(o: T): (keyof T)[] => Object.keys(o) as (keyof T)[];

export type SpeccedReqOptions = {
  abortController?: AbortController;
  errorMiddlewares?: ErrorMiddleware[];
};
export type UnspeccedReqOptions = {
  abortController?: AbortController;
  body?: string;
  headers?: Record<string, string>;
  errorMiddlewares?: ErrorMiddleware[];
};

export const serializeRequest = (
  path: string,
  inputLocations: Record<string, InputLocation>,
  inputCodec: t.Type<Record<string, unknown>>,
  input: Record<string, unknown>,
) => {
  // { and } will get query-encoded by URL, so use : for path params instead.
  let serializedPath = path.replace(/{(.*?)}/g, ':$1');
  const encodedInput = inputCodec.encode(input);
  const queryParams: Record<string, string> = {};
  const headers: Record<string, string> = {};
  let body: string | Buffer | undefined;
  getKeys(inputLocations).forEach((inputKey) => {
    const inputLocation = inputLocations[inputKey];
    const inputValue = encodedInput[inputKey];
    if (inputValue === undefined) { return; }
    if (inputLocation === 'query') {
      if (inputValue instanceof Array) {
        queryParams[`${inputKey}`] = inputValue.map(v => encodeURIComponent(`${v}`)).join(',');
      } else {
        queryParams[`${inputKey}`] = encodeURIComponent(`${inputValue}`);
      }
    } else if (inputLocation === 'body') {
      if (t.type({ content: StringBufferCodec }).is(input.body)) {
        body = input.body.content;
        headers['Content-Type'] = 'application/octet-stream';
        headers['Content-Length'] = input.body.content.byteLength.toString();
      } else {
        body = JSON.stringify(inputValue);
      }
    } else if (inputLocation === 'path') {
      serializedPath = serializedPath.replace(
        new RegExp(`:${inputKey}`, 'g'),
        () => `${inputValue}`,
      );
    } else if (inputLocation === 'header') {
      headers[`${inputKey}`] = `${inputValue}`;
    }
  });
  return {
    path: serializedPath,
    queryParams,
    headers,
    body,
    input: encodedInput,
  };
};

export type ErrorMiddleware = (opts: { err: MableError | MableServerResponseError, href: string}) => (MableError | MableServerResponseError);

export class ApiClient {
  public readonly config: Config;

  public readonly baseCorrelationId: string;

  public get nextCorrelationId() : string {
    return `${this.baseCorrelationId}.${this.requestNumber}`;
  }

  private requestNumber: number;

  public headers: Record<string, string> = {};

  public badAccessTokenHandler?: (
    errorResponse: MableServerResponseError,
  ) => Promise<(string | undefined)>;

  private timeoutError = new MableError({
    code: 'Timeout',
    message: 'The request timed out.',
    displayTitle: 'Timeout Error',
    displayMessage: 'The request failed to complete in a timely manner.',
    data: undefined,
  });

  public constructor(config: Config) {
    this.config = config;
    this.baseCorrelationId = config.prettyCorrelationIds ? config.uniqueId.slice(0, 4) : config.uniqueId;
    this.requestNumber = 0;
    this.headers = {
      ...this.headers,
      ...config.additionalHeaders,
    };
  }

  private async baseReq(
    href: string,
    inputReq: RequestInit,
    opts: { retry?: boolean, errorMiddlewares?: ErrorMiddleware[] } = {},
  ): Promise<{ res: Response; currentRequestNumber: number; href: string }> {
    const {
      retry = true,
      errorMiddlewares = [],
    } = opts;
    const currentRequestNumber = this.requestNumber;
    const correlationId = this.nextCorrelationId;
    this.requestNumber += 1;
    const req: RequestInit = {
      credentials: 'include',
      ...inputReq,
      headers: {
        // TODO: Non-JSON requests.
        'Content-Type': 'application/json',
        'Correlation-Id': correlationId,
        ...this.headers,
        ...inputReq.headers,
      },
    };

    this.config.loggingDelegate && this.config.loggingDelegate.log(`[api-client] Making request #${currentRequestNumber} ${href} ${JSON.stringify(req, null, 2)}`);

    // Make the request
    let res: Response;
    if (this.config.timeout && this.config.timeout > 0) {
      res = await Promise.race([
        this.config.fetch(href, req),
        new Promise((_, reject) => setTimeout(() => reject(this.timeoutError), this.config.timeout)),
      ]) as Response; // safe to cast this as Response as the second promise will only ever reject.
    } else {
      res = await this.config.fetch(href, req);
    }

    // Handle 401 errors if a handler is provided
    if (res.status === 401 && this.badAccessTokenHandler && retry) {
      const body = await getJsonBody(res, { loggingDelegate: this.config.loggingDelegate, currentRequestNumber, href });
      this.config.loggingDelegate && this.config.loggingDelegate.log(`[api-client] Received 401 for #${currentRequestNumber} ${href}, attempting refresh.`);
      const errorResponse = new MableServerResponseError(body, res);
      const newAccessToken = await this.badAccessTokenHandler(errorResponse);
      if (newAccessToken) {
        // Update the token
        const updatedHeaders = {
          ...this.headers,
          Authorization: `Bearer ${newAccessToken}`,
        };
        this.headers = updatedHeaders;
        // Try the request again
        return this.baseReq(href, inputReq, { retry: false, errorMiddlewares });
      }
      // Fail for real
      throw errorResponse;
    }

    if (res.status < 200 || res.status > 299) {
      const body = await getJsonBody(res, { loggingDelegate: this.config.loggingDelegate, currentRequestNumber, href });
      const err = errorMiddlewares.reduce<MableError | MableServerResponseError>(
        (err, middleware) => middleware({ err, href }),
        new MableServerResponseError(body, res, { correlationId, logData: { href } }),
      );
      throw err;
    }

    return { res, currentRequestNumber, href };
  }

  public async unspeccedReq(
    method: string,
    path: string,
    opts?: UnspeccedReqOptions,
  ) {
    const url = new URL(`${this.config.baseUrl}${path}`);
    const { res, currentRequestNumber, href } = await this.baseReq(url.href, {
      method,
      ...(opts?.abortController ? { signal: opts.abortController.signal } : {}),
      body: opts?.body,
      headers: opts?.headers,
    }, opts);
    // We can't log the response body here, since someone else probably wants to read it - see the comment on getJsonBody.
    // eslint-disable-next-line no-unused-expressions
    this.config.loggingDelegate?.log(`[api-client] Got response #${currentRequestNumber} ${href} for unspecced request`);
    return res;
  }

  public async speccedReq<ResponseType>(
    method: HTTPMethod,
    path: string,
    accept: string,
    inputLocations: Record<string, InputLocation>,
    inputCodec: t.Type<Record<string, unknown>>,
    responseCodec: t.Type<ResponseType>,
    input: Record<string, unknown>,
    opts?: {
      abortController?: AbortController;
      errorMiddlewares?: ErrorMiddleware[];
      responseCodecs?: Record<string, t.Type<unknown>>;
    },
  ): Promise<ApiResponse<ResponseType>> {
    // Serialize inputs into request
    const headers = {
      Accept: accept,
    };
    const serializedReq = serializeRequest(
      path,
      inputLocations,
      inputCodec,
      input,
    );
    Object.assign(headers, serializedReq.headers);
    const req: RequestInit = {
      method: method?.toUpperCase(),
      headers,
      body: serializedReq.body,
      ...(opts?.abortController ? { signal: opts.abortController.signal } : {}),
    };
    const url = new URL(`${this.config.baseUrl}${serializedReq.path}`);
    Object.entries(serializedReq.queryParams).forEach(([key, val]) => url.searchParams.append(key, val));

    const errorMiddlewares: ErrorMiddleware[] = [
      ...opts?.errorMiddlewares ?? [],
      ({ err, href }) => {
        if (!opts?.responseCodecs) {
          return err;
        }
        const errorResponseStatusCodes = Object.keys(opts.responseCodecs).map(Number).filter(n => n >= 400);
        if (errorResponseStatusCodes.length === 0) {
          return err;
        }
        if (!(err instanceof MableServerResponseError)) {
          return err;
        }
        if (err.status && errorResponseStatusCodes.includes(err.status)) {
          const decodedError = opts.responseCodecs[err.status].decode(err.body);
          if (isLeft(decodedError)) {
            return err;
          }
          if (isMableError(decodedError.right)) {
            return decodedError.right;
          }
          return new MableServerResponseError(err.body, err.rawResponse, {
            ...err,
            data: decodedError.right,
          });
        }
        return err;
      },
    ];
    const { res, currentRequestNumber, href } = await this.baseReq(url.href, req, { errorMiddlewares });

    const body = await (async () => {
      switch (res.headers.get('content-type')) {
        case 'application/octet-stream':
          return Buffer.from(await res.arrayBuffer());
        default:
          return getJsonBody(res, { loggingDelegate: this.config.loggingDelegate, currentRequestNumber, href });
      }
    })();
    const decodedBody = responseCodec.decode(body);
    if ('left' in decodedBody) throw new DecodeError(decodedBody.left);
    return { body: decodedBody.right, rawResponse: res };
  }
}
/** Permissive branded slug type, so old clients won't fail if we update the
* slug requirements. This should never be used anywhere we actually care about
* slugs being correct (prod-api, admin-web, data-processing). The types are
* compatible because it prevents typescript from getting real mad.
*/
export const PermissiveSlug = t.brand(
  t.string,
  (_s): _s is t.Branded<string, SlugBrand> => true,
  'Slug',
);
export type PermissiveSlug = t.TypeOf<typeof PermissiveSlug>;

export const StringBufferCodec = new t.Type<Buffer, string, unknown>(
  'StringBufferCodec',
  (u): u is Buffer => u instanceof Buffer,
  (u, c) => {
    if (u instanceof Buffer) {
      return t.success(u);
    }
    const s = t.string.validate(u, c);
    if ('left' in s) return s;
    return t.success(Buffer.from(s.right, 'utf-8'));
  },
  u => u.toString('utf-8'),
);
export type StringBuffer = t.TypeOf<typeof StringBufferCodec>;
